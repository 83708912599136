import React, { useEffect, useState } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';

import { node, bool } from 'prop-types';
import { Button } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';

const fileName = `kartu-akses-masuk-${THE_BRIDE}`.replace(' ', '-');

function ExportToPNG({ componentRef, autoDownload }) {
  const [loading, setLoading] = useState(false);

  const onExport = () => {
    setLoading(true);
    exportComponentAsPNG(componentRef, { fileName: `${fileName}.png` });

    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  useEffect(() => {
    if (autoDownload) {
      onExport();
    }
  }, []);

  return (
    <Button
      bgColor="bgAlternative"
      color="white"
      size="sm"
      fontSize="md"
      fontStyle="italic"
      fontWeight="bold"
      className="animation-heart"
      colorScheme="blackAlpha"
      fontFamily="body"
      isLoading={loading}
      onClick={onExport}
    >
      Download E-Ticket
    </Button>
  );
}

ExportToPNG.propTypes = {
  autoDownload: bool.isRequired,
  componentRef: node,
};

export default ExportToPNG;